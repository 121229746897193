import styled from "@emotion/styled"
import { Button, Tab, Tabs } from "@mui/material"
import { useTranslations } from "next-intl"
import Link from "next/link"
import { useRef, useState } from "react"
import { NewText } from "ui"
import { sharedColors } from "ui/theme/colors"
import { Apartment, useOutsideClick } from "utils"
import { choosenProject, submenuClick } from "utils/gtm"
import ContactForm from "../Projects/Form"
import AccordionInnerDetails from "../Projects/Marketing/AccordionInnerDetails"
import { a11yProps } from "../Projects/Marketing/Apartments"
import { ProjectsMessages } from "../Projects/Projects"
import { Backdrop } from "../Projects/StandardProject"

const StyledInner = styled.div`
  gap: 24px;
  padding: 24px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 24px;
  }
  background-color: #f7f7f7;
`
const TabsContainer = styled(Tabs)`
  .MuiTabs-flexContainer {
    display: flex;
    gap: 24px;
    ${(props) => props.theme.breakpoints.down("md")} {
      overflow-x: scroll;
    }
  }
`

const TabsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const StyledTab = styled(Tab)<{ active: boolean }>`
  min-height: 0;
  padding: 0;
  min-width: 0;
  padding-bottom: 12px;
  font-size: 16px;
  line-height: 20px;
  color: ${({ active }) => (active ? "black" : "#6F6F6F")};
  font-weight: ${({ active }) => (active ? "600" : "400")};
  border-bottom: ${({ active }) => (active ? `2px solid ${sharedColors.oldBrandBlue}` : "")};
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 18px;
    line-height: 22px;
  }
`

const StickyFormWrap = styled.div`
  width: 100%;
  position: fixed;

  left: 0px;
  z-index: 10000;
  max-height: calc(100% - 40px);
  overflow: auto;
  ${(props) => props.theme.breakpoints.down("sm")} {
    bottom: 0px;
    height: 100%;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const StyledButton = styled(Button)`
  align-self: end;
  background-color: #2768a3;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 18px;
  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 20px;
  }
  &:hover {
    background-color: #2768a3;
    box-shadow: none;
    border: none;
  }
`
const StyledActive = styled.div`
  flex-grow: 1;
`

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  border-bottom: 1px solid black;
  display: flex;
  width: fit-content;
`

type TabProps = {
  value: number
  index: number
  apartment: Apartment
}

function ActiveTab({ index, value, apartment }: TabProps) {
  const getType = (): "plan" | "specs" | "docs" => {
    switch (index) {
      case 0:
        return "plan"
      case 1:
        return "specs"
      case 2:
        return "docs"
      default:
        return "plan"
    }
  }
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <AccordionInnerDetails apartment={apartment} type={getType()} />}
    </div>
  )
}

type Props = {
  apartment: Apartment
  formSubjects: Record<string, string>
  messages: ProjectsMessages
  bmbyId?: number
  status?: any
}

function RentAccordionInner({ apartment, formSubjects, messages, bmbyId, status }: Props) {
  const t = useTranslations("AccordionDetails")
  const [value, setValue] = useState(0)
  const [isBackdropVisible, setIsBackdropVisible] = useState(false)
  const [isFormVisible, setIsFormVisible] = useState(false)
  const formRef = useRef(null)
  const handleFormClose = () => {
    setIsFormVisible(false)
    setIsBackdropVisible(false)
  }
  const handleFormOpen = () => {
    choosenProject("מעוניינ/ת בדירה", apartment?.propertyModel)
    submenuClick("מעוניינ/ת בדירה", apartment?.propertyModel)
    setIsFormVisible(true)
    setIsBackdropVisible(true)
  }
  useOutsideClick([formRef], handleFormClose)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    submenuClick(
      newValue === 0 ? "תוכנית דירה" : newValue === 1 ? "תוכנית דירה" : "מסמכים וחוזים",
      apartment?.propertyModel,
    )
    setValue(newValue)
  }
  const formData = {
    price: apartment.price,
    currency: apartment.priceCurrency,
    area: apartment.areaInSquareMeters,
    floor: apartment.floor,
    rooms: apartment.numberOfRooms,
  }
  return (
    <>
      <StyledInner>
        <TabsContent>
          <TabsContainer value={value} onChange={handleChange} aria-label="property types">
            <StyledTab active={0 === value} key={0} label={t("gallery")} {...a11yProps(0)} />
            <StyledTab active={1 === value} key={1} label={t("apartmentPlan")} {...a11yProps(1)} />
            <StyledTab active={2 === value} key={2} label={t("agreementDocs")} {...a11yProps(2)} />
          </TabsContainer>
        </TabsContent>
        <StyledActive>
          {Array.from([0, 1, 2]).map((el, i) => (
            <ActiveTab key={i} index={i} value={value} apartment={apartment} />
          ))}
        </StyledActive>
        <StyledButtonWrapper>
          <StyledLink
            href={`projects/${apartment.umbracoName?.toLowerCase().replace(" ", "-")}`}
            target="_blank"
          >
            <NewText
              component={"p"}
              desktopFontSize={16}
              desktopLineHeight={"23px"}
              desktopFontWeight={400}
              color={sharedColors.black}
            >
              {t("moreabout")}
            </NewText>
          </StyledLink>
          <StyledButton variant="outlined" onClick={() => handleFormOpen()}>
            {t("wanttorent")}
          </StyledButton>
        </StyledButtonWrapper>
      </StyledInner>
      {isBackdropVisible && <Backdrop z={120} />}
      {isFormVisible ? (
        <StickyFormWrap ref={formRef}>
          <ContactForm
            formData={formData}
            messages={messages}
            formSubjects={formSubjects}
            onClose={handleFormClose}
            bmbyId={bmbyId}
            status={status}
            title={messages.rentforyou}
            type={"rent"}
          />
        </StickyFormWrap>
      ) : null}
    </>
  )
}

export default RentAccordionInner
